/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/

body {
	font-weight: $default-font-weight;
}

a {
	&:active,
	&:hover,
	&:focus {
		outline: 0;
		text-decoration: none;
	}
}

input:active {
	outline: 0;
	text-decoration: none;
}

button {
	&:active {
		outline: 0;
		text-decoration: none;
	}
	outline: 0;
	text-decoration: none;
}


input:active {
	outline: 0;
	text-decoration: none;
}

textarea:focus,
input:focus {
	outline: 0;
	text-decoration: none;
}