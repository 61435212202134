/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:46:47
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/

// Opacity
//@mixin opacity($value) {
//	opacity: $value;
//	filter: alpha(opacity=#{$value * 100});
//}
@mixin opacity($opacity) {
	opacity: $opacity;

	// IE8 filter
	$opacity-ie: $opacity * 100;

	filter: #{alpha(opacity = $opacity-ie)};
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity-ie});
}
