/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:48:12
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/


//-- RGBa Color / Background
@mixin color-rgba($color, $opacity) {
	color: rgba(red($color), green($color), blue($color), $opacity);
}

@mixin background-rgba($color, $opacity) {
	background: rgba(red($color), green($color), blue($color), $opacity);
}