// Outer container
.progress {
	height: 10px;
}

// Bar of progress
.progress-bar {
	line-height: 10px;

	.inactive & {
		@include no-transition;
	}
}

.async-message ~ .progress {
	margin-top: 5px;
}

.async-activa {
	.progress {
		margin-bottom: 10px;
	}
}

.fileupload-widget {
	.progress   {
		margin-top: 5px;
		margin-bottom: 10px;
	}

	.progress + .fileinput-button {
		margin-bottom: 5px;
	}
}
