/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:39:34
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/


/*@mixin font-size($size: 12, $base: 16) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}*/
@mixin font-size($size: 12, $base: $font-size-base) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
	&::-moz-placeholder {
		color: $color;
		opacity: 1;
	}

	// See https://github.com/twbs/bootstrap/pull/11526
	&:-ms-input-placeholder {
		color: $color;
	}

	// Internet Explorer 10+
	&::-webkit-input-placeholder {
		color: $color;
	}

	// Safari and Chrome
}

%text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
