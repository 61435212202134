/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:36:06
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/

@mixin transition($property, $speed) {
	-webkit-transition: $property $speed ease;
	-moz-transition: $property $speed ease;
	-o-transition: $property $speed ease;
	transition: $property $speed ease;
}

@mixin no-transition {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

// Transforms
@mixin rotate($deg) {
	-webkit-transform: rotate($deg);
	-moz-transform: rotate($deg);
	-ms-transform: rotate($deg);
	transform: rotate($deg);
}

@mixin translate($val) {
	-webkit-transform: translate($val);
	-moz-transform: translate($val);
	-ms-transform: translate($val);
	transform: translate($val);
}

@mixin translateX($value) {
	-webkit-transform: translateX($value);
	-moz-transform: translateX($value);
	-ms-transform: translateX($value);
	-o-transform: translateX($value);
	transform: translateX($value);
}

@mixin translateY($val) {
	-webkit-transform: translateY($val);
	-ms-transform: translateY($val);

	// IE9 only
	-o-transform: translateY($val);
	transform: translateY($val);
}

// (tret de bootstrap))
@mixin translate($x, $y) {
	-webkit-transform: translate($x, $y);
	-ms-transform: translate($x, $y);

	// IE9 only
	-o-transform: translate($x, $y);
	transform: translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
	-moz-transform: translate3d($x, $y, $z);
	-ms-transform: translate3d($x, $y, $z);
	-o-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

// Transitions (tret de bootstrap))
@mixin transition($transition...) {
	-webkit-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin transition-property($transition-property...) {
	-webkit-transition-property: $transition-property;
	transition-property: $transition-property;
}

@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
	-moz-transition-delay: $transition-delay;
	-o-transition-delay: $transition-delay;
	-ms-transition-delay: $transition-delay;
	transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration...) {
	-webkit-transition-duration: $transition-duration;
	transition-duration: $transition-duration;
}

@mixin transition-timing-function($timing-function) {
	-webkit-transition-timing-function: $timing-function;
	transition-timing-function: $timing-function;
}

@mixin transition-transform($transition...) {
	-webkit-transition: -webkit-transform $transition;
	-moz-transition: -moz-transform $transition;
	-o-transition: -o-transform $transition;
	transition: transform $transition;
}

// Tret de bootstrap
// Transformations
@mixin scale($ratio...) {
	-webkit-transform: scale($ratio);
	-ms-transform: scale($ratio);

	// IE9 only
	-o-transform: scale($ratio);
	transform: scale($ratio);
}

@mixin scaleX($ratio) {
	-webkit-transform: scaleX($ratio);
	-ms-transform: scaleX($ratio);

	// IE9 only
	-o-transform: scaleX($ratio);
	transform: scaleX($ratio);
}

@mixin scaleY($ratio) {
	-webkit-transform: scaleY($ratio);
	-ms-transform: scaleY($ratio);

	// IE9 only
	-o-transform: scaleY($ratio);
	transform: scaleY($ratio);
}

@mixin skew($x, $y) {
	-webkit-transform: skewX($x) skewY($y);
	-ms-transform: skewX($x) skewY($y);

	// See https://github.com/twbs/bootstrap/issues/4885; IE9+
	-o-transform: skewX($x) skewY($y);
	transform: skewX($x) skewY($y);
}

@mixin translate($x, $y) {
	-webkit-transform: translate($x, $y);
	-ms-transform: translate($x, $y);

	// IE9 only
	-o-transform: translate($x, $y);
	transform: translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-ms-transform: rotate($degrees);

	// IE9 only
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}

@mixin rotateX($degrees) {
	-webkit-transform: rotateX($degrees);
	-ms-transform: rotateX($degrees);

	// IE9 only
	-o-transform: rotateX($degrees);
	transform: rotateX($degrees);
}

@mixin rotateY($degrees) {
	-webkit-transform: rotateY($degrees);
	-ms-transform: rotateY($degrees);

	// IE9 only
	-o-transform: rotateY($degrees);
	transform: rotateY($degrees);
}

@mixin perspective($perspective) {
	-webkit-perspective: $perspective;
	-moz-perspective: $perspective;
	perspective: $perspective;
}

@mixin perspective-origin($perspective) {
	-webkit-perspective-origin: $perspective;
	-moz-perspective-origin: $perspective;
	perspective-origin: $perspective;
}

@mixin transform-origin($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;

	// IE9 only
	transform-origin: $origin;
}