/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:41:16
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/

// Responsive background
@mixin responsive-background {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

//	@media #{$tablet-l} {
//		-webkit-background-size: auto;
//		-moz-background-size: auto;
//		-o-background-size: auto;
//		background-size: auto;
//	}
}


// Vertical gradient, from top to bottom
// By default set to: none
@mixin gradient-striped($color: rgba(255, 255, 255, 0.15), $angle: 45deg) {
	background-image: none;
	background-image: none;
}