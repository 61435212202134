/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:38:51
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/

// Border Radius
// The length of a border-radius to be used by default.
$default-border-radius: 5px !default;


// Border radius.
@mixin border-radius($radius, $important: "") {
	-webkit-border-radius: unquote("#{$radius}#{$important}");
	-moz-border-radius: unquote("#{$radius}#{$important}");
	-ms-border-radius: unquote("#{$radius}#{$important}");
	-o-border-radius: unquote("#{$radius}#{$important}");
	border-radius: unquote("#{$radius}#{$important}");
}

@mixin no-border-radius {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
}

// Round radius at position by amount.
// * legal values for `$vert`: `top`, `bottom`
// * legal values for `$horz`: `left`, `right`
@mixin border-corner-radius($vert, $horz, $radius: $default-border-radius, $important: "") {
	-webkit-border-#{$vert}-#{$horz}-radius: unquote("#{$radius}#{$important}");
	-moz-border-#{$vert}-#{$horz}-radius: unquote("#{$radius}#{$important}");
	-ms-border-#{$vert}-#{$horz}-radius: unquote("#{$radius}#{$important}");
	-o-border-#{$vert}-#{$horz}-radius: unquote("#{$radius}#{$important}");
	border-#{$vert}-#{$horz}-radius: unquote("#{$radius}#{$important}");
}

// Round top-left corner only
@mixin border-top-left-radius($radius: $default-border-radius) {
	@include border-corner-radius(top, left, $radius);
}

// Round top-right corner only
@mixin border-top-right-radius($radius: $default-border-radius) {
	@include border-corner-radius(top, right, $radius);
}

// Round bottom-left corner only
@mixin border-bottom-left-radius($radius: $default-border-radius) {
	@include border-corner-radius(bottom, left, $radius);
}

// Round bottom-right corner only
@mixin border-bottom-right-radius($radius: $default-border-radius) {
	@include border-corner-radius(bottom, right, $radius);
}

// Round both top corners by amount
@mixin border-top-radius($radius: $default-border-radius) {
	@include border-top-left-radius($radius);
	@include border-top-right-radius($radius);
}

// Round both right corners by amount
@mixin border-right-radius($radius: $default-border-radius) {
	@include border-top-right-radius($radius);
	@include border-bottom-right-radius($radius);
}

// Round both bottom corners by amount
@mixin border-bottom-radius($radius: $default-border-radius) {
	@include border-bottom-left-radius($radius);
	@include border-bottom-right-radius($radius);
}

// Round both left corners by amount
@mixin border-left-radius($radius: $default-border-radius) {
	@include border-top-left-radius($radius);
	@include border-bottom-left-radius($radius);
}
