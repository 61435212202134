/*
Copyright (C) 2016 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 03/12/2016, 04:24:27
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/
@import "variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap";