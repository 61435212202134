/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/

.checkbox {
	padding-left: 20px;
}

.checkbox label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px;
	margin-right: 10px;
}

.checkbox label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 18px;
	height: 18px;
	left: 0;
	top: 2px;
	margin-left: -20px;
	border: 1px solid #ccc;
	border-radius: 0px;
	background-color: #fff;
	transition: border 0.15s ease-in-out;
}

.checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 18px;
	height: 18px;
	left: 0;
	top: 2px;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: $brand-primary;
}

.checkbox input.checkbox,
.checkbox input[type="checkbox"] {
	opacity: 0;
	z-index: 1;
	transition: all 0.15s ease-in-out;
}

.checkbox input.checkbox:focus + label::before,
.checkbox input[type="checkbox"]:focus + label::before {
	border-color: $brand-primary;
}

.checkbox input.checkbox.checked + label::after,
.checkbox input[type="checkbox"]:checked + label::after {
	content: "";
	background-color: $brand-primary;
	width: 10px;
	height: 10px;
	top: 6px;
	left: 4px;
	transition: all 0.15s ease-in-out;
}

.checkbox input.checkbox:disabled + label,
.checkbox input[type="checkbox"]:disabled + label {
	opacity: 0.65;
	cursor: not-allowed;
}

.checkbox input.checkbox.disabled + label::before,
.checkbox input[type="checkbox"]:disabled + label::before {
	background-color: #ddd;
	cursor: not-allowed;
}


.radio {
	padding-left: 20px;

	label {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		padding-left: 5px;
		line-height: 1.5;

		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			width: 17px;
			height: 17px;
			left: 0;
			margin-left: -20px;
			border: 1px solid #ccc;
			border-radius: 50%;
			background-color: #fff;
			transition: border 0.15s ease-in-out;
		}

		&::after {
			display: inline-block;
			position: absolute;
			content: " ";
			width: 9px;
			height: 9px;
			left: 4px;
			top: 4px;
			margin-left: -20px;
			border-radius: 50%;
			background-color: $brand-primary;
			transform: scale(0, 0);
			transition: 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
		}
	}

	input[type="radio"] {
		opacity: 0;
		z-index: 1;

		&:focus + label::before {
			border-color: $brand-primary;
		}

		&:checked + label::after {
			transform: scale(1, 1);
		}

		&:disabled + label {
			opacity: 0.65;

			&::before {
				cursor: not-allowed;
			}
		}
	}

	&.radio-inline {
		margin-top: 0;
	}

	&.radio-dark label {
		&::before {
			background-color: #444;
			border: 0;
		}

		&::after {
			width: 11px;
			height: 11px;
			left: 3px;
			top: 3px;
		}
	}
}

.input-group-addon {
	border-radius: 0;
	background-color: $brand-primary;
	border-color: #097e00;
	color: #fff;
}
