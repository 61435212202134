.checker {
	position: relative;
	display: inline-block;
	margin: 0;

	cursor: pointer;

	input.radio,
	input.checkbox,
	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		z-index: 1;
		width: 20px;
		height: 20px;
		margin-left: -20px;
		margin-top: 0;
		cursor: pointer;
		opacity: 0;
		padding: 0;
		font-size: 1em !important;

		&.checked + i:before,
			&:checked + i:before {
			background-color: inherit;
			/*border-width: 0;*/
		}

		&.checked + span .active,
		&:checked + span .active {
			display: inherit;
		}

		&.radio + i,
		&[type="radio"] + i {
			border-radius: 50%;

			&:before {
				border-radius: 50%;
			}
		}

		&.checkbox.checked + i:after,
			&[type="checkbox"]:checked + i:after {
			position: absolute;
			top: 2px;
			left: 6px;
			display: table;
			width: 6px;
			height: 12px;
			border: 2px solid rgba(0, 0, 0, 0.75);
			border-top: 0;
			border-left: 0;
			content: ' ';
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		&.radio.checked + i:after,
			&[type="radio"]:checked + i:after {
			position: absolute;
			top: 6px;
			left: 6px;
			display: table;
			width: 6px;
			height: 6px;
			background: rgba(0, 0, 0, 0.75);
			border-radius: 50%;
			content: ' ';
		}

		&[disabled] + i:before {
			border-color: rgba(0, 0, 0, 0.26);
		}
	}

	> i {
		position: relative;
		display: inline-block;
		width: 0;
		height: 18px;
		margin-top: -2px;
		margin-right: 20px;
		margin-left: -20px;
		line-height: 1;
		vertical-align: middle;

		&:before {
			position: absolute;
			width: 18px;
			height: 100%;
			border: 2px solid rgba(0, 0, 0, 0.55);
			border-radius: 2px;
			content: "";
			-webkit-transition: none;
			-moz-transition: none;
			-o-transition: none;
			transition: none;
		}

		&.no-icon:after {
			display: none !important;
		}
	}
}

.checkbox-confirmar {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-left: 10px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;

	label {
		margin-bottom: 0;
		font-weight: normal !important;
	}

	input[type="checkbox"] {
		position: absolute;
		margin-left: -20px;
		margin-top: 4px \9;
	}

	&.has-error {
		color: $brand-danger;

		label {
			color: $brand-danger;
		}
	}
}

.checkbox-inline {
	label {
		margin-bottom: 0;
	}

	> .checker {
		margin-left: 10px;
	}
}

// Per separar <button> + confirmació del contingut següent.
.btn + .checkbox-inline {
	margin-right: 10px;

	&:last-child {
		margin-right: inherit;
	}
}

.radio-inline {
	label {
		margin-bottom: 0;
	}
	> .checker {
		margin-left: 10px;
	}
}
