/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:47:14
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix {
	&:before,
		&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}