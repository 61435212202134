/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:46:16
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/

// Drop shadows
// By default set to: none
@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	// iOS <4.3 & Android <4.1
	box-shadow: $shadow;
}
