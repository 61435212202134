/* Header */
$header-bg-color:		#FFF;
$header-font-color:		#000;
$header-font-size:		18px;
$header-font-weight:	500;

$usernav-font-size:			12px;
$usernav-font-color:		#555;
$usernav-icon-font-size:	18px;
$usernav-icon-font-color:	#555;

$usernav-padding-top: 8px;
$usernav-padding-bottom: 8px;

/* Colors */
$text-error-color:	#EE2D20;

/* Sidebar */
$sidebar-collapsed-width: 38px;

$sidebar-menu-link-font-size:	16px;
$sidebar-menu-link-font-weight:	500;

$sidebar-menu-link-icon-font-size:	18px;
$sidebar-menu-link-icon-font-width:	24px;
