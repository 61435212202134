/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 29/01/2017, 02:45:23
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/


// Forms
@mixin form-control-focus($input-border-color) {
	&:focus {
		border-color: $input-border-color;
		outline: 0;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
}
