// Utiltizem les variables definides al bootstrap

$screen-sm-min:              $screen-sm !default;
$screen-md-min:              $screen-md !default;
$screen-lg-min:              $screen-lg !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$grid-float-breakpoint:     $screen-sm-min !default;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

@mixin MINQ($canvas) {
	@if $canvas == S {
		@media (min-width: $screen-sm-min) { @content; }
	}
	@else if $canvas == M {
		@media (min-width: $screen-md-min) { @content; }
	}
	@else if $canvas == L {
		@media (min-width: $screen-lg-min) { @content; }
	}
}

@mixin MAXQ($canvas) {
	@if $canvas == XS {
		@media (max-width: $screen-xs-max) { @content; }
	}
	@else if $canvas == S {
		@media (max-width: $screen-sm-max) { @content; }
	}
	@else if $canvas == M {
		@media (max-width: $screen-md-max) { @content; }
	}
}

@mixin MQ($canvas) {
	@if $canvas == XS {
		@media (max-width: $screen-xs-max) { @content; }
	}
	@if $canvas == S {
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
	}
	@else if $canvas == M {
		@media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
	}
	@else if $canvas == L {
		@media (min-width: $screen-lg-min) { @content; }
	}
}

@mixin COLLAPSED() {
	@media (max-width: $grid-float-breakpoint-max) { @content; }
}

@mixin UNCOLLAPSED() {
	@media (min-width: $grid-float-breakpoint) { @content; }
}