/*
Copyright (C) 2016 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 05/11/2016, 13:52:02
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/

.field-error, 
.text-error {
	color: $text-error-color;
}

select {
	&:focus,
		&::-moz-focus-inner {
		outline: 0 !important;
	}

	&:-moz-focusring {
		color: transparent !important;
		text-shadow: 0 0 0 #000 !important;
	}

	option {
		outline: 0 none !important;
		&:focus,
			&::-moz-focus-inner {
			outline: 0 none !important;
		}
	}
}

.checkbox,
.checkbox-inline {
	padding-left: 20px;
}

.checkbox label,
.checkbox-inline label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px;
	margin-right: 10px;
}

.checkbox label::before,
.checkbox-inline label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 18px;
	height: 18px;
	left: 0;
	top: 2px;
	margin-left: -20px;
	border: 1px solid #ccc;
	border-radius: 0px;
	background-color: #fff;
	transition: border 0.15s ease-in-out;
}

.checkbox label::after,
.checkbox-inline label::after {
	display: inline-block;
	position: absolute;
	width: 18px;
	height: 18px;
	left: 0;
	top: 2px;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: $brand-primary;
}

.checkbox input.checkbox,
.checkbox input[type="checkbox"],
.checkbox-inline input.checkbox,
.checkbox-inline input[type="checkbox"] {
	opacity: 0;
	z-index: 1;
	transition: all 0.15s ease-in-out;
}

.checkbox input.checkbox:focus + label::before,
.checkbox input[type="checkbox"]:focus + label::before,
.checkbox-inline input.checkbox:focus + label::before,
.checkbox-inline input[type="checkbox"]:focus + label::before {
	border-color: $brand-primary;
}

.checkbox input.checkbox.checked + label::after,
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox-inline input.checkbox.checked + label::after,
.checkbox-inline input[type="checkbox"]:checked + label::after {
	content: "";
	background-color: $brand-primary;
	width: 10px;
	height: 10px;
	top: 6px;
	left: 4px;
	transition: all 0.15s ease-in-out;
}

.checkbox input.checkbox:disabled + label,
.checkbox input[type="checkbox"]:disabled + label,
.checkbox-inline input.checkbox:disabled + label,
.checkbox-inline input[type="checkbox"]:disabled + label {
	opacity: 0.65;
	cursor: not-allowed;
}

.checkbox input.checkbox.disabled + label::before,
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox-inline input.checkbox.disabled + label::before,
.checkbox-inline input[type="checkbox"]:disabled + label::before {
	background-color: #ddd;
	cursor: not-allowed;
}

.checkbox.checkbox-inline,
.checkbox-inline.checkbox-inline {
	margin-top: 0;
}

.radio {
	padding-left: 20px;

	label {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		padding-left: 5px;
		line-height: 1.5;

		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			width: 17px;
			height: 17px;
			left: 0;
			margin-left: -20px;
			border: 1px solid #ccc;
			border-radius: 50%;
			background-color: #fff;
			transition: border 0.15s ease-in-out;
		}

		&::after {
			display: inline-block;
			position: absolute;
			content: " ";
			width: 9px;
			height: 9px;
			left: 4px;
			top: 4px;
			margin-left: -20px;
			border-radius: 50%;
			background-color: $brand-primary;
			transform: scale(0, 0);
			transition: 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
		}
	}

	input[type="radio"] {
		opacity: 0;
		z-index: 1;

		&:focus + label::before {
			border-color: $brand-primary;
		}

		&:checked + label::after {
			transform: scale(1, 1);
		}

		&:disabled + label {
			opacity: 0.65;

			&::before {
				cursor: not-allowed;
			}
		}
	}

	&.radio-inline {
		margin-top: 0;
	}

	&.radio-dark label {
		&::before {
			background-color: #444;
			border: 0;
		}

		&::after {
			width: 11px;
			height: 11px;
			left: 3px;
			top: 3px;
		}
	}
}

.input-group-addon {
	border-radius: 0;
	background-color: $brand-primary;
	border-color: #097e00;
	color: #fff;
}

/*.has-success {
	.royal-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline {
		color: #019450;
	}

	.form-control {
		border-color: #02c66c;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

		&:focus {
			border-color: #019450;
			box-shadow: none;
		}
	}

	.input-group-addon {
		color: #02c66c;
		background-color: #dff0d8;
		border-color: #02c66c;
	}

	.form-control-feedback {
		color: #02c66c;
	}
}*/
/*
.has-warning {
	.royal-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline {
		color: #ec971f;
	}

	.form-control {
		border-color: #f0ad4e;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

		&:focus {
			border-color: #c77c11;
			box-shadow: none;
		}
	}

	.input-group-addon {
		color: #f0ad4e;
		background-color: #dff0d8;
		border-color: #f0ad4e;
	}

	.form-control-feedback {
		color: #f0ad4e;
	}
}*/
/*
.has-error {
	.royal-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline {
		color: #c9302c;
	}

	.form-control {
		border-color: #d9534f;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

		&:focus {
			border-color: #a02622;
			box-shadow: none;
		}
	}

	.input-group-addon {
		color: #d9534f;
		background-color: #dff0d8;
		border-color: #d9534f;
	}

	.form-control-feedback {
		color: #d9534f;
	}
}*/

/*.form-group .glyphicon {
	margin-top: 5px;
}*/

/*
.checker {
	position: relative;
	display: inline-block;
	margin: 0;

	cursor: pointer;

	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		z-index: 1;
		width: 20px;
		height: 20px;
		margin-left: -20px;
		margin-top: 0;
		cursor: pointer;
		opacity: 0;

		font-size: 1em !important;


		&:checked + i:before {
			background-color: inherit;
		}

		&:checked + span .active {
			display: inherit;
		}

		&[type="radio"] + i {
			border-radius: 50%;

			&:before {
				border-radius: 50%;
			}
		}

		&[type="checkbox"]:checked + i:after {
			position: absolute;
			top: 2px;
			left: 6px;
			display: table;
			width: 6px;
			height: 12px;
			border: 2px solid rgba(0, 0, 0, 0.75);
			border-top: 0;
			border-left: 0;
			content: ' ';
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		&[type="radio"]:checked + i:after {
			position: absolute;
			top: 6px;
			left: 6px;
			display: table;
			width: 6px;
			height: 6px;
			background: rgba(0, 0, 0, 0.75);
			border-radius: 50%;
			content: ' ';
		}

		&[disabled] + i:before {
			border-color: rgba(0, 0, 0, 0.26);
		}
	}

	> i {
		position: relative;
		display: inline-block;
		width: 0;
		height: 18px;
		margin-top: -2px;
		margin-right: 20px;
		margin-left: -20px;
		line-height: 1;
		vertical-align: middle;

		&:before {
			position: absolute;
			width: 18px;
			height: 100%;
			border: 2px solid rgba(0, 0, 0, 0.55);
			border-radius: 2px;
			content: "";
			-webkit-transition: none;
			-moz-transition: none;
			-o-transition: none;
			transition: none;
		}

		&.no-icon:after {
			display: none !important;
		}
	}
}

.checkbox-inline {
	label {
		margin-bottom: 0;
	}
	> .checker {
		margin-left: 10px;
	}
}

.radio-inline {
	label {
		margin-bottom: 0;
	}
	> .checker {
		margin-left: 10px;
	}
}
*/