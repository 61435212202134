

$gray-base:		#333;

$brand-primary:         #3062A2;
$brand-success:         #88CC00;
$brand-info:            #5bc0de !default;
$brand-warning:         #ccaa00;
$brand-danger:          #cc0022;

$input-color:			 #111;
$input-border-focus:	$brand-primary;
$input-bg-disabled:		#EEE;

$link-color:            #555;
$link-hover-color:      $brand-primary;
$link-hover-decoration: none;

$font-family-sans-serif:	Roboto, sans-serif !default;
$font-size-base:			14px;

/*$line-height-base:        1.5;*/

$default-font-weight:    300;	/* Aixó NO es del bootstrap... */

/*$headings-font-family:    inherit !default;*/
$headings-font-weight:    500;
/*$headings-line-height:    1.1 !default;*/
/*$headings-color:          inherit !default;*/


/*
$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;
*/

/*
$height-base:                  40px !default;

$font-size-base:               14px !default;
$font-size-small:              12px !default;
$font-size-medium:             16px !default; 
$font-size-large:              18px !default;
$font-size-large-navbar:       20px !default;

$font-size-h1:                 52px !default;
$font-size-h2:                 36px !default;
$font-size-h3:                 28px !default;
$font-size-h4:                 22px !default;
$font-size-h5:                 16px !default;
$font-size-h6:                 14px !default;
$font-paragraph:               16px !default;
$font-size-navbar:             16px !default;
$font-size-small:              12px !default;

$font-weight-light:          300 !default; 
$font-weight-normal:         400 !default;
$font-weight-semi:           500 !default;
$font-weight-bold:           600 !default;
*/



//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    40px;

$navbar-default-color:             #777;
$navbar-default-bg:                transparent;

// Navbar links
/*$navbar-default-link-color:                #777 !default;
$navbar-default-link-hover-color:          #333 !default;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         #555 !default;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;*/

// Navbar brand label
/*$navbar-default-brand-color:               $navbar-default-link-color !default;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) !default;
$navbar-default-brand-hover-bg:            transparent !default;*/

// Navbar toggle
/*
$navbar-default-toggle-hover-bg:           transparent;
$navbar-default-toggle-icon-bar-bg:        $brand-primary;
$navbar-default-toggle-border-color:       #ccc;
*/

@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";